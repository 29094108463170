<template>
  <div class="div-container">
    <div class="div-counter-container">
      <div class="counter-container">
        <i class="fab fa-instagram fa-3x"></i>
        <span>+ DE</span>
        <div class="counter" data-target="7000"></div>
        <span>Trabalhos Executados</span>
      </div>
      <div class="counter-container">
        <i class="fab fa-facebook fa-3x"></i>
        <span>+ DE</span>
        <div class="counter" data-target="2500"></div>
        <span>Clientes Atendidos</span>
      </div>
      <div class="counter-container">
        <i class="fab fa-facebook fa-3x"></i>
        <span>+ DE</span>
        <div class="counter" data-target="500"></div>
        <span>Condomínios Atendidos</span>
      </div>
      <div class="counter-container">
        <i class="fab fa-youtube fa-3x"></i>
        <span>+ DE</span>
       <div class="counter" data-target="12500"></div>
        <span>Metros de redes de proteção foram instalados</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HelloWorld',
  data () {
    return {
      contador: 0,
      conter: 0
    }
  },
  props: {
    msg: String
  },
  mounted: function () {
    const counters = document.querySelectorAll('.counter')

    counters.forEach((counter) => {
      counter.innerText = '0'
      const updateCounter = () => {
        const target = +counter.getAttribute('data-target')
        const count = +counter.innerText
        const increment = target / 200
        this.conter += 50
        if (this.conter < target) {
          counter.innerText = `${Math.ceil(count + increment).toLocaleString('de-DE')}`
          setTimeout(updateCounter, 1)
        } else counter.innerText = target.toLocaleString('de-DE')
      }
      updateCounter()
    })
  }
}
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

.counter-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #16153D;
  border: 1px solid #E1E1E1;
  max-width: 100%!important;
}

.div-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%!important;
  div{
    margin: 0!important;
    padding: 0!important;
  }
}

.div-counter-container {
  margin: 0!important;
  padding: 0!important;
  display: grid;
  grid-template-columns: repeat(2,calc((100% - 40px)/2));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  @media only screen and (max-width: 600px) {
    max-width: 100%!important;
    grid-column-gap: 0;
    grid-row-gap: 0;
    grid-template-columns: repeat(1,100%)
  }
  background: #fff;
}

.counter,
.counterp {
    font-size: 40px;
    font-size: 2.6666666666667rem;
    font-weight: 700;
    font-family: 'Montserrat',sans-serif;
}
</style>
