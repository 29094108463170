<template>
  <section class="dpost">
    <ul style="background-color: #fff;">
      <li class="post bck-overlay">
        <h4 class="txt-normal">Campinas Redes de Proteção</h4>
        <h2>+15 Anos de Experiência</h2>
        <p>A empresa possui uma equipe de profissionais altamente qualificados e treinados para realizar instalações
          precisas e eficientes, seguindo rigorosamente as normas de segurança e qualidade.</p>
        <a href="https://api.whatsapp.com/send?phone=5519992114069&amp;text=Olá%20Campinas%20Redes%20de%20Proteção,%20gostaria%20de%20solicitar%20um%20orçamento."><button class="b2"><img class="icon32" src='@/assets/whatsapp-32.svg'/> <span> Entre em contato</span></button></a>
      </li>
      <li>
        <Count />
      </li>
    </ul>
  </section>

  <section class="gallery">
    <div class="tag-desc">
      <h4 class="txt-normal">Conheça um pouco dos</h4>
      <h2 class="heading-title">Nossos Serviços</h2>
    </div>
    <ul class="">
      <li class="itens" v-for="(slide, index) in slides" v-bind:key="slide.titulo"
        v-bind:style="{ 'background-image': 'url(' + slide.Img + ')' }">
        <div class="titulo">{{ slide.titulo }}</div>
        <div :name="index" class="">{{ slide.sub }}</div>
      </li>
    </ul>
  </section>
  <section class="dpost">
    <ul>
      <li><img src="@/assets/img03.jpeg" alt="" width="500" height="500"></li>
      <li class="bck-overlay post">
        <h3>Fale com um especialista para orçar sua rede de proteção!</h3>
        <p>Há quase 20 anos no mercado de redes e telas de proteção, prestando serviços de qualidade para os mais
          diversos
          setores.</p>
          <a href="tel:1922232571"><button class="b2"><img class="icon32" src='@/assets/phone-32.svg'/> <span> Ligue Agora!</span></button></a>
      </li>
    </ul>
  </section>
</template>
<script>
import Count from '@/components/Count.vue'
export default {
  name: 'Home',
  components: {
    Count
  },
  data () {
    return {
      slides: [
        {
          titulo: 'Proteção Para Pets',
          sub: 'Redes de Proteção',
          Img: require('@/assets/img24.jpg')
        },
        {
          titulo: 'Proteção para Janelas',
          sub: 'Redes de Proteção',
          Img: require('@/assets/img25.jpg')
        },
        {
          titulo: 'Proteção para Varandas',
          sub: 'Redes de Proteção',
          Img: require('@/assets/img22.jpeg')
        },
        {
          titulo: 'Proteção Para Campos de futebol',
          sub: 'Redes de Proteção',
          Img: require('@/assets/img23.jpg')
        },
        {
          titulo: 'Proteção Para Quadras poliesportivas',
          sub: 'Redes de Proteção',
          Img: require('@/assets/img18.jpeg')
        },
        {
          titulo: 'Proteção Para Playgrounds',
          sub: 'Redes de Proteção',
          Img: require('@/assets/img17.jpeg')
        },
        {
          titulo: 'Proteção Para Pula Pula',
          sub: 'Redes de Proteção',
          Img: require('@/assets/img27.jpg')
        },
        {
          titulo: 'Proteção Para piscinas',
          sub: 'Redes de Proteção',
          Img: require('@/assets/img08.jpeg')
        },
        {
          titulo: 'Proteção Anti-pássaros',
          sub: 'Redes de Proteção',
          Img: require('@/assets/img15.jpeg')
        },
        {
          titulo: 'Proteção Para Cercamento de piscinas',
          sub: 'Redes de Proteção',
          Img: require('@/assets/img26.jpg')
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
/* * {
  max-width: 100% !important
} */
section{
  margin:7rem 0!important;
}
img, span{
  vertical-align: middle;
}
.txt-pers {
  font-size: 2.6666666666667rem;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
}

.dpost {
  display: grid;
  background: #fff;

  ul {
    margin: 0 !important;
    padding: 0 !important;
    display: grid;
    grid-template-columns: repeat(2, calc((100%)/2));

    @media only screen and (max-width: 600px) {
      grid-template-columns: repeat(1, 100vw)
    }
  }
}

.gallery {
  display: grid;

  ul {
    li {
      height: 400px;
      background-size: 100% 100%;
      object-fit: fill;
      display: flex;
      align-items: center;
      justify-content: end;
      flex-direction: column;
      padding-bottom: 1rem !important;

      .titulo {
        font-size: 1.3333333333333rem;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
      }

      color:#fff
    }

    margin: 0 !important;
    padding: 0 !important;
    display: grid;
    grid-template-columns: repeat(5, calc((100%)/5));
    /* grid-column-gap: 20px;
    grid-row-gap: 20px; */

    @media only screen and (max-width: 600px) {
      grid-template-columns: repeat(2, 50vw)
    }
  }
}

.bck-overlay {
  padding: 1rem !important;
  background-color: orange;
  opacity: 0.81;
}
button.b2{
    background: #16153D;
  }
  button{
  margin: 4px;
  border: 0;
  border-radius: 50px;
  padding: .75rem 2.75rem;
  color: #fff;
  box-shadow: 0 .5rem 1.5rem rgba(0,0,0,.25);
  cursor: pointer;
  position: relative;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &:hover {
    -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  transform: translateY(-5px);
    box-shadow: 0 1rem 1.5rem rgba(0,0,0,.25);
  }
  }
</style>
